var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"1200"},model:{value:(_vm.showPreview),callback:function ($$v) {_vm.showPreview=$$v},expression:"showPreview"}},[_c('v-card',[_c('v-card-title',[_c('v-layout',{attrs:{"justify-space-between":""}},[_vm._v(" "+_vm._s(_vm.showPreviewItem)+" "),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){_vm.showPreview = false}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1)],1),_c('v-card-text',[(_vm.fileAndVideoCheck('.pdf'))?_c('iframe',{staticClass:"mb-4",attrs:{"src":_vm.serverUrl +
          '/files/' +
          _vm.websiteId +
          '/' +
          _vm.showPreviewItem +
          '?token=' +
          _vm.userToken,"width":"100%","height":"600px"}}):_vm._e(),(_vm.fileAndVideoCheck('.mp4'))?_c('v-layout',{attrs:{"justify-center":""}},[_c('video',{staticClass:"mb-4",attrs:{"controls":"","width":"100%"}},[_c('source',{attrs:{"src":_vm.serverUrl +
              '/files/' +
              _vm.websiteId +
              '/' +
              _vm.showPreviewItem +
              '?token=' +
              _vm.userToken,"type":"video/mp4"}})])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }