var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('pageElementFilesDialog',{key:_vm.websiteId + _vm.showPreviewItem,attrs:{"websiteId":_vm.websiteId,"showPreviewItem":_vm.showPreviewItem,"showPreviewProp":_vm.showPreview},on:{"update:showPreview":function($event){_vm.showPreview = $event}}}),(_vm.item.TranslatedFiles)?_c('v-select',{staticClass:"max-w-select-lang",attrs:{"items":_vm.itemLanguages,"label":_vm.$t('Language')},model:{value:(_vm.itemDefaultLanguage),callback:function ($$v) {_vm.itemDefaultLanguage=$$v},expression:"itemDefaultLanguage"}}):_vm._e(),_vm._l((_vm.item.TranslatedFiles
      ? _vm.item.Value[_vm.itemDefaultLanguage]
      : _vm.item.Value),function(file,index){return [(file != '')?_c('div',{key:index + file},[_c('h3',[_c('v-icon',[_vm._v("mdi-paperclip")]),_vm._v(" "+_vm._s(file)+" "),(!_vm.showPreview && _vm.includesPDFOrMP4(file))?_c('v-btn',{attrs:{"loading":_vm.isDownloading,"color":"primary","icon":""},on:{"click":function($event){return _vm.setItemForPreview(file)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1):(_vm.showPreview && _vm.includesPDFOrMP4(file))?_c('v-btn',{attrs:{"loading":_vm.isDownloading,"color":"primary","icon":""},on:{"click":function($event){_vm.showPreview = false}}},[_c('v-icon',[_vm._v("mdi-eye-off")])],1):_vm._e(),_c('v-btn',{attrs:{"loading":_vm.isDownloading,"color":"primary","icon":""},on:{"click":function($event){return _vm.downloadFile(file)}}},[_c('v-icon',[_vm._v("mdi-file-download")])],1),(
            !_vm.item.ReadOnly ||
            _vm.editItemAdmin ||
            (_vm.$store.getters.hasUserRoleForPageItem({
              role: 2,
            }) &&
              !_vm.userIsAdministrator)
          )?_c('v-btn',{attrs:{"color":"error","icon":"","loading":_vm.isDownloading},on:{"click":function($event){return _vm.deleteFile(
              _vm.item.TranslatedFiles
                ? _vm.item.Value[_vm.itemDefaultLanguage]
                : _vm.item.Value,
              index
            )}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)]):_vm._e()]}),_vm._l((this.files),function(file,index){return [_c('h3',{key:index},[_c('v-icon',[_vm._v("mdi-paperclip")]),_vm._v(" "+_vm._s(file.name)+" "),(!_vm.item.ReadOnly)?_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.deleteFile(
            _vm.item.TranslatedFiles
              ? _vm.item.Value[_vm.itemDefaultLanguage]
              : _vm.item.Value,
            index
          )}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)]}),(!_vm.item.ReadOnly || _vm.editItemAdmin)?_c('input',{ref:'filesInput' + _vm.objectName,staticClass:"fileInput",attrs:{"multiple":"","type":"file","accept":_vm.item.SpecificFileTypes},on:{"change":function($event){return _vm.setSelectedFiles($event.target.files)}}}):_vm._e(),(!_vm.item.ReadOnly || _vm.editItemAdmin)?_c('v-btn',{staticClass:"mt-3 ml-0 mr-3 edit-page-action-button mb-4",attrs:{"color":"primary"},on:{"click":function($event){_vm.$refs['filesInput' + _vm.objectName].click()}}},[_vm._v(_vm._s(_vm.$t("Choose files")))]):_vm._e(),(_vm.uploadError.length > 0)?_c('errorHandeling',{attrs:{"snackbarText":_vm.uploadError,"buttons":[
      {
        isText: true,
        functionName: 'reportError',
        text: 'Report error',
      },
      {
        isText: true,
        functionName: 'clearError',
        text: 'Close',
      },
    ],"snackbarColor":"error","snackbarTimout":"-1","snackbarIcon":"mdi-alert-circle"},on:{"clearError":function($event){return _vm.$emit('setUploadError', '')}}}):_vm._e(),(_vm.$store.getters.editMode)?[_c('hr',{staticClass:"mt-5"}),_c('v-row',{staticClass:"my-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-layout',{attrs:{"column":""}},[_c('v-text-field',{staticClass:"mw-w",attrs:{"hide-details":"auto","type":"number","max":"12","min":"1","dense":"","label":_vm.$t('Column width')},model:{value:(_vm.item.colWidth),callback:function ($$v) {_vm.$set(_vm.item, "colWidth", _vm._n($$v))},expression:"item.colWidth"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"hide-details":"auto","label":_vm.$t('Read-only')},model:{value:(_vm.item.ReadOnly),callback:function ($$v) {_vm.$set(_vm.item, "ReadOnly", $$v)},expression:"item.ReadOnly"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"hide-details":"auto","label":_vm.$t('Translate files')},on:{"change":function($event){return _vm.makeOneArray()}},model:{value:(_vm.item.TranslatedFiles),callback:function ($$v) {_vm.$set(_vm.item, "TranslatedFiles", $$v)},expression:"item.TranslatedFiles"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"hide-details":"auto","label":_vm.$t('Set file types')},model:{value:(_vm.item.SpecificFile),callback:function ($$v) {_vm.$set(_vm.item, "SpecificFile", $$v)},expression:"item.SpecificFile"}}),(_vm.item.SpecificFile)?_c('v-select',{staticClass:"mw-w",attrs:{"items":_vm.$config.fileTypes,"label":_vm.$t('Files'),"multiple":"","chips":"","clearable":""},model:{value:(_vm.item.SpecificFileTypes),callback:function ($$v) {_vm.$set(_vm.item, "SpecificFileTypes", $$v)},expression:"item.SpecificFileTypes"}}):_vm._e()],1)],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }