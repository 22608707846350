<template>
  <div>
    <pageElementFilesDialog
      :key="websiteId + showPreviewItem"
      :websiteId="websiteId"
      :showPreviewItem="showPreviewItem"
      :showPreviewProp="showPreview"
      @update:showPreview="showPreview = $event"
    />
    <v-select
      v-if="item.TranslatedFiles"
      v-model="itemDefaultLanguage"
      :items="itemLanguages"
      :label="$t('Language')"
      class="max-w-select-lang"
    ></v-select>
    <!-- There are files in the list -->
    <template
      v-for="(file, index) in item.TranslatedFiles
        ? item.Value[itemDefaultLanguage]
        : item.Value"
    >
      <div :key="index + file" v-if="file != ''">
        <h3>
          <v-icon>mdi-paperclip</v-icon>
          {{ file }}
          <v-btn
            :loading="isDownloading"
            color="primary"
            icon
            @click="setItemForPreview(file)"
            v-if="!showPreview && includesPDFOrMP4(file)"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn
            :loading="isDownloading"
            color="primary"
            icon
            @click="showPreview = false"
            v-else-if="showPreview && includesPDFOrMP4(file)"
          >
            <v-icon>mdi-eye-off</v-icon>
          </v-btn>
          <v-btn
            :loading="isDownloading"
            color="primary"
            icon
            @click="downloadFile(file)"
          >
            <v-icon>mdi-file-download</v-icon>
          </v-btn>

          <v-btn
            v-if="
              !item.ReadOnly ||
              editItemAdmin ||
              ($store.getters.hasUserRoleForPageItem({
                role: 2,
              }) &&
                !userIsAdministrator)
            "
            color="error"
            icon
            :loading="isDownloading"
            @click="
              deleteFile(
                item.TranslatedFiles
                  ? item.Value[itemDefaultLanguage]
                  : item.Value,
                index
              )
            "
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </h3>
      </div>
    </template>
    <template v-for="(file, index) in this.files">
      <h3 :key="index">
        <v-icon>mdi-paperclip</v-icon>
        {{ file.name }}
        <v-btn
          v-if="!item.ReadOnly"
          color="error"
          icon
          @click="
            deleteFile(
              item.TranslatedFiles
                ? item.Value[itemDefaultLanguage]
                : item.Value,
              index
            )
          "
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </h3>
    </template>
    <input
      v-if="!item.ReadOnly || editItemAdmin"
      :ref="'filesInput' + objectName"
      class="fileInput"
      multiple
      type="file"
      :accept="item.SpecificFileTypes"
      @change="setSelectedFiles($event.target.files)"
    />
    <v-btn
      v-if="!item.ReadOnly || editItemAdmin"
      class="mt-3 ml-0 mr-3 edit-page-action-button mb-4"
      @click="$refs['filesInput' + objectName].click()"
      color="primary"
      >{{ $t("Choose files") }}</v-btn
    >

    <errorHandeling
      v-if="uploadError.length > 0"
      :snackbarText="uploadError"
      :buttons="[
        {
          isText: true,
          functionName: 'reportError',
          text: 'Report error',
        },
        {
          isText: true,
          functionName: 'clearError',
          text: 'Close',
        },
      ]"
      snackbarColor="error"
      snackbarTimout="-1"
      snackbarIcon="mdi-alert-circle"
      @clearError="$emit('setUploadError', '')"
    />
    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />

      <v-row no-gutters class="my-5">
        <v-col cols="12">
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              dense
              :label="$t('Column width')"
              class="mw-w"
            ></v-text-field>
            <v-switch
              v-model="item.ReadOnly"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Read-only')"
            ></v-switch>
            <v-switch
              v-model="item.TranslatedFiles"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Translate files')"
              @change="makeOneArray()"
            ></v-switch>
            <v-switch
              v-model="item.SpecificFile"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Set file types')"
            ></v-switch>

            <v-select
              v-if="item.SpecificFile"
              :items="$config.fileTypes"
              v-model="item.SpecificFileTypes"
              :label="$t('Files')"
              multiple
              chips
              clearable
              class="mw-w"
            >
            </v-select>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import PageElementFilesDialog from "../pageElementFilesDialog.vue";

export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    websiteId: {
      type: String,
    },
    pageId: {
      type: String,
    },
    objectName: {
      type: [Array, Object, String],
    },
    uploadError: {},
    editItemAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      userToken: this.$auth.userToken(),
      serverUrl: this.$helpers.getServerUrl(),
      userIsAdministrator: this.$auth.userIsAdministrator(),
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.$config.contentLanguages[0],
      showPreview: false,
      showPreviewItem: null,
      isDownloading: false,
      files: null,
      uploading: false,
    };
  },
  components: {
    PageElementFilesDialog,
  },
  methods: {
    deleteFile(files, index) {
      files.splice(index, 1);
    },
    getItemLanguages() {
      return this.$config.contentLanguages;
    },

    setItemForPreview(file) {
      this.showPreviewItem = file;
      this.showPreview = true;
    },
    includesPDFOrMP4(file) {
      return file.includes(".pdf") || file.includes(".mp4");
    },
    downloadFile(file) {
      this.isDownloading = true;
      this.$request.download(
        "/files/" + this.websiteId + "/" + file + "?token=" + this.userToken,
        { responseType: "blob" },
        file,
        () => {
          this.isDownloading = false;
        }
      );
    },
    setSelectedFiles(fileList) {
      this.uploading = true;
      this.$emit("setUploadError", "");
      this.$emit("update:saving", true);

      this.files = Array.prototype.slice.call(fileList);

      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i]);
      }

      this.$request.post(
        "/content/fileupload/" + this.pageId,
        formData,
        (res) => {
          this.uploading = false;
          this.$refs["filesInput" + this.objectName].value = null;
          this.$emit("update:saving", false);

          if (res.success) {
            if (this.item["TranslatedFiles"]) {
              if (this.item.Value[this.itemDefaultLanguage]) {
                this.item.Value[this.itemDefaultLanguage].push(...res.data);
              } else if (!this.item.Value) {
                this.item.Value = {};
                this.item.Value[this.itemDefaultLanguage] = res.data;
              } else {
                this.item.Value[this.itemDefaultLanguage] = {};
                this.item.Value[this.itemDefaultLanguage] = res.data;
              }
            } else {
              if (this.item.Value) {
                this.item.Value = this.item.Value.concat(res.data);
              } else {
                this.item.Value = res.data;
              }
            }
            this.files = null;
          } else {
            this.files = null;
            this.$emit("setUploadError", res.message);
          }
        }
      );
    },
    makeOneArray() {
      if (this.item.Value) {
        if (this.item.TranslatedFiles) {
          this.item.Value = { Nl: this.item.Value };
        } else {
          this.item.Value =
            this.item.Value["Nl"] ||
            this.item.Value["Fr"] ||
            this.item.Value["En"];
        }
      }
    },
  },
};
</script>

<style scoped>
.fileInput {
  display: none;
}
.max-w-select-lang {
  max-width: 4rem;
}
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
</style>